import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "../auth.service";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public step: "initiate" | "confirm" | "redirect" = "initiate";
  public resetPasswordForm: FormGroup;
  public setPasswordForm: FormGroup;
  public error = "";
  public busy = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}
  private passwordPattern =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!*])(?!.*\s).{8,}$/;

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        email: [undefined, [Validators.required, Validators.email]],
      },
      { updateOn: "blur" }
    );

    this.setPasswordForm = this.fb.group(
      {
        code: ["", [Validators.required]],
        password: [
          undefined,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(this.passwordPattern),
          ],
        ],
      },
      { updateOn: "blur" }
    );
  }

  public get email() {
    return this.resetPasswordForm.get("email") as FormControl;
  }

  public get code() {
    return this.setPasswordForm.get("code") as FormControl;
  }

  public get password() {
    return this.setPasswordForm.get("password") as FormControl;
  }

  public sendEmail() {
    this.error = "";
    if (!this.resetPasswordForm.valid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    this.busy = true;
    this.authService
      .forgotPassword(this.email.value)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => (this.step = "confirm"),
        () => (this.error = "Please enter a valid email address")
      );
  }

  public setPassword() {
    this.error = "";
    if (!this.setPasswordForm.valid) {
      this.setPasswordForm.markAllAsTouched();
      return;
    }

    this.busy = true;
    this.authService
      .confirmForgotPassword(
        this.email.value,
        this.code.value,
        this.password.value
      )
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.step = "redirect";
          setTimeout(() => this.router.navigate(["/machines"]), 3000);
        },
        () => (this.error = "Please enter a valid code")
      );
  }
}
