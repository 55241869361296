import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CognitoService } from "./cognito.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public loading = true;
  public loggedIn: boolean = null;

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  constructor(private cognitoService: CognitoService) {}

  getUser(): Observable<any> {
    return this.cognitoService.getUser().pipe(
      catchError(() => of(null)),
      tap((u) => this.userProfileSubject$.next(u))
    );
  }

  login(username: string, password: string) {
    return this.cognitoService.login(username, password);
  }

  logout(): Observable<void> {
    return this.cognitoService.logout();
  }

  getToken(): Observable<string> {
    return this.cognitoService.getToken();
  }

  isAuthenticated(): Observable<boolean> {
    return this.cognitoService.isAuthenticated();
  }

  verifyEmail(email: string, code: string): Observable<boolean> {
    return this.cognitoService.verifyEmail(email, code);
  }

  forgotPassword(email: string): Observable<void> {
    return this.cognitoService.forgotPassword(email);
  }

  confirmForgotPassword(
    email: string,
    code: string,
    password: string
  ): Observable<void> {
    return this.cognitoService.confirmForgotPassword(email, code, password);
  }
}
