import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, finalize } from "rxjs/operators";
import {
  AirgpuApiService,
  CreateAccountParams,
} from "src/app/core/http/airgpu-api.service";
import { AuthService } from "../auth.service";
import { Account } from "gen/typescript-angular-client";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  public step: "signup" | "verify" | "redirect" = "signup";
  public authCheck$: Observable<boolean>;
  public signupForm: FormGroup;
  public verifyEmailForm: FormGroup;
  public error = false;
  public busy = false;
  public account: Account;

  constructor(
    private fb: FormBuilder,
    private apiService: AirgpuApiService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    if (!environment.features.signup) {
      this.router.navigate(["/login"]);
    }

    const passwordPattern =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!*])(?!.*\s).{8,}$/;

    this.signupForm = this.fb.group(
      {
        email: [undefined, [Validators.required, Validators.email]],
        password: [
          undefined,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(passwordPattern),
          ],
        ],
        terms: [false, [Validators.requiredTrue]],
        marketing_consent: [false],
        captcha: [undefined, Validators.required],
        referral_code: [undefined],
      },
      { updateOn: "blur" }
    );

    this.verifyEmailForm = this.fb.group(
      {
        code: ["", [Validators.required]],
      },
      { updateOn: "blur" }
    );

    this.route.queryParams.pipe(filter((p) => p.r)).subscribe((p) =>
      this.signupForm.patchValue({
        referral_code: p.r,
      })
    );
  }

  ngOnInit(): void {
    this.authCheck$ = this.authService.isAuthenticated();
    this.authCheck$.subscribe((authenticated) => {
      if (authenticated) {
        this.router.navigate(["/machines"]);
      }
    });
  }

  // workaround for safari not setting checkbox value automatically
  public toggleTerms(event: any) {
    this.signupForm.patchValue({
      terms: event.target.checked,
    });
  }

  // workaround for safari not setting checkbox value automatically
  public toggleMarketingConsent(event: any) {
    this.signupForm.patchValue({
      marketing_consent: event.target.checked,
    });
  }

  public signup() {
    this.error = false;

    if (!this.signupForm.valid) {
      this.signupForm.markAllAsTouched();
      return;
    }

    // ensure email is all lowercase
    this.signupForm.patchValue({
      email: (this.signupForm.value.email as string).toLowerCase(),
    });

    // create account
    this.busy = true;
    this.apiService
      .createAccount(this.signupForm.value as CreateAccountParams)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        (account) => {
          this.account = account;
          this.step = "verify";
        },
        () => {
          this.error = true;
        }
      );
  }

  public verifyEmail() {
    this.error = false;

    if (!this.verifyEmailForm.valid) {
      this.verifyEmailForm.markAllAsTouched();
      return;
    }
    this.busy = true;
    this.authService
      .verifyEmail(this.account.users[0], this.code.value)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe({
        complete: () => {
          this.step = "redirect";
          setTimeout(() => this.router.navigate(["/machines"]), 3000);
        },
        error: () => {
          this.error = true;
        },
      });
  }

  public get email() {
    return this.signupForm.get("email") as FormControl;
  }

  public get password() {
    return this.signupForm.get("password") as FormControl;
  }

  public get terms() {
    return this.signupForm.get("terms") as FormControl;
  }

  public get captcha() {
    return this.signupForm.get("captcha") as FormControl;
  }

  public get code() {
    return this.verifyEmailForm.get("code") as FormControl;
  }
}
