import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Account,
  ApiKey,
  Charge,
  DataCenter,
  FeedbackParams,
  Machine,
  MachineParams,
  MachineService,
  Session,
} from "gen/typescript-angular-client";
import { Observable, throwError } from "rxjs";
import { delay, map, retryWhen, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";

interface StartMachineParams {
  client_ip?: string;
  provisioning_type?: "spot" | "on-demand";
}

export interface Subscription {
  id: string;
  update_url: string;
  cancel_url: string;
  state: string;
  ended: boolean;
  ends_at?: number;
  renews_at?: number;
}

export interface Price {
  gross: number;
  net: number;
  tax: number;
}

export interface Pricing {
  customer_country: string;
  products: [
    {
      currency: string;
      list_price: Price;
      price: Price;
      product_id: number;
      product_title: string;
      subscription: {
        frequency: number;
        interval: string;
        list_price: Price;
        price: Price;
        trial_days: 0;
      };
      vendor_set_prices_included_tax: true;
    }
  ];
}

export interface MachinePrice {
  instance_type: string;
  provisioning_type: string;
  provider: string;
  region: string;
  price: number;
  max_upload: number;
  os: string;
  gpu_model?: string;
}

export interface CreateMachineParams extends Partial<MachineParams> {}

export interface ConfigureMachineParams {
  instance_type?: string;
  max_upload?: number;
  root_volume_size: number;
}

export interface Goals {
  signed_up: number;
  created_machine: number;
  initial_credit_purchase: number;
  following_credit_purchase: number;
}

export interface Statistics {
  playtime: number;
  credit_purchases: number;
  rating: number;
  goals: Goals;
}

export interface PaymentCheckParams {
  account: string;
  amount: number;
}

export enum PaymentCheckStatus {
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum PaymentCheckReason {
  RecentPayment = "recent_payment",
  AccountLimitReached = "account_limit_reached",
  ForbiddenAmount = "forbidden_amount",
  EmailNotVerified = "email_not_verified",
  BlacklistedEmailProvider = "blacklisted_email_provider",
  BlacklistedIP = "blacklisted_ip",
}

export interface PaymentCheckResponse {
  result: PaymentCheckStatus;
  reason?: PaymentCheckReason;
  preferred_processor: string;
}

export interface CreateAccountParams {
  email: string;
  password: string;
  terms: boolean;
  marketing_consent: boolean;
  captcha: string;
  referral_code?: string;
}

export interface AddGameStreamClientParams {
  pin: string;
}

export interface ListSessionsParams {
  machine?: string;
  isFinished?: boolean;
  sort?: string;
}

export interface ListChargesParams {
  sort?: string;
  limit?: number;
  skip?: number;
  amount?: string;
  time?: string;
}

export interface CreateCheckoutSessionParams {
  price_id: string;
  promotion_code?: string;
}

export interface CreateCheckoutSessionResult {
  url: string;
}

@Injectable({
  providedIn: "root",
})
export class AirgpuApiService {
  constructor(
    private http: HttpClient,
    private machineService: MachineService
  ) {}

  startMachine(id: string, params: StartMachineParams): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/machines/${id}/start`;
    return this.http.post(url, params).pipe(map(() => null));
  }

  pollUntilMachineInitializing(
    id: string,
    maxRetries: number = 18
  ): Observable<Machine> {
    var retries = 0;
    return this.getMachine(id).pipe(
      map((m) => {
        if (
          m.status === "initializing" ||
          m.status === "running" ||
          m.status === "off"
        ) {
          return m;
        }
        throw m;
      }),
      retryWhen((err) => {
        if (retries++ < maxRetries) {
          return err.pipe(
            // retry in 10 seconds
            delay(10000)
          );
        }
        return throwError("max retries reached");
      })
    );
  }

  pollUntilMachineStarted(
    id: string,
    maxRetries: number = 18
  ): Observable<Machine> {
    var retries = 0;
    return this.getMachine(id).pipe(
      map((m) => {
        if (m.status === "running" || m.status === "off") {
          return m;
        }
        throw m;
      }),
      retryWhen((err) => {
        if (retries++ < maxRetries) {
          return err.pipe(
            // retry in 10 seconds
            delay(10000)
          );
        }
        return throwError("max retries reached");
      })
    );
  }

  stopMachine(id: string): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/machines/${id}/stop`;
    return this.http.post(url, {}).pipe(map(() => null));
  }

  pollUntilMachineBackedUp(
    id: string,
    maxRetries: number = 45
  ): Observable<Machine> {
    var retries = 0;
    return this.getMachine(id).pipe(
      map((m) => {
        if (m.status === "off") {
          return m;
        }
        throw m;
      }),
      retryWhen((err) => {
        if (retries++ < maxRetries) {
          return err.pipe(
            // retry in 1 minute
            delay(60000)
          );
        }
        return throwError("max retries reached");
      })
    );
  }

  pollUntilMachineStopped(
    id: string,
    maxRetries: number = 18
  ): Observable<Machine> {
    var retries = 0;
    return this.getMachine(id).pipe(
      map((m) => {
        if (m.status === "backing_up" || m.status === "off") {
          return m;
        }
        throw m;
      }),
      retryWhen((err) => {
        if (retries++ < maxRetries) {
          return err.pipe(
            // retry in 10 seconds
            delay(10000)
          );
        }
        return throwError("max retries reached");
      })
    );
  }

  // get the subscription with the given id
  getSubscription(id: string) {
    const url = `${environment.backend.apiBaseUrl}/subscriptions/${id}`;
    return this.http.get<Subscription>(url);
  }

  // get pricing information
  getPricing(): Observable<Pricing> {
    const url = `${environment.backend.apiBaseUrl}/pricing`;
    return this.http.get<Pricing>(url).pipe(timeout(10000));
  }

  // get machine prices
  getMachinePrices(): Observable<MachinePrice[]> {
    const url = `${environment.backend.apiBaseUrl}/machine-prices`;
    return this.http.get<MachinePrice[]>(url).pipe(timeout(10000));
  }

  // create a new machine
  createMachine(params: CreateMachineParams): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/machines`;
    return this.http.post(url, params).pipe(map(() => null));
  }

  // delete a machine
  deleteMachine(id: string): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/machines/${id}`;
    return this.http.delete<void>(url);
  }

  // (re)configure a machine
  configureMachine(
    id: string,
    params: ConfigureMachineParams
  ): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/machines/${id}/configure`;
    return this.http.post(url, params).pipe(map(() => null));
  }

  // statistics
  getStatistics(from: Date, until: Date): Observable<Statistics> {
    const url = `${environment.backend.apiBaseUrl}/statistics`;
    const params = new HttpParams()
      .append("from", "" + from.getTime())
      .append("until", "" + until.getTime());
    return this.http.get<Statistics>(url, { params });
  }

  runPaymentCheck(
    params: PaymentCheckParams
  ): Observable<PaymentCheckResponse> {
    const url = `${environment.backend.apiBaseUrl}/payment-checks`;
    return this.http.post<PaymentCheckResponse>(url, params);
  }

  createAccount(params: CreateAccountParams): Observable<Account> {
    const url = `${environment.backend.apiBaseUrl}/accounts`;
    return this.http.post<Account>(url, params);
  }

  resendActivationEmail(accountId: string): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/accounts/${accountId}/activation-emails`;
    return this.http.post<void>(url, {});
  }

  deleteAccount(accountId: string): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/accounts/${accountId}`;
    return this.http.delete<void>(url);
  }

  addGameStreamClient(machineId: string, params: AddGameStreamClientParams) {
    const url = `${environment.backend.apiBaseUrl}/machines/${machineId}/gamestream-clients`;
    return this.http.post<void>(url, params);
  }

  listMachines(): Observable<Machine[]> {
    const url = `${environment.backend.apiBaseUrl}/machines`;
    return this.http.get<Machine[]>(url);
  }

  listSessions(params?: ListSessionsParams): Observable<Session[]> {
    const url = `${environment.backend.apiBaseUrl}/sessions`;
    const queryParams: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {};
    if (params.machine) {
      queryParams.machine = params.machine;
    }
    if (params.isFinished) {
      queryParams.is_finished = params.isFinished;
    }
    if (params.sort) {
      queryParams.sort = params.sort;
    }
    return this.http.get<Session[]>(url, {
      params: queryParams,
    });
  }

  getAccount(accountId: string): Observable<Account> {
    const url = `${environment.backend.apiBaseUrl}/accounts/${accountId}`;
    return this.http.get<Account>(url);
  }

  listCharges(params?: ListChargesParams): Observable<Charge[]> {
    const url = `${environment.backend.apiBaseUrl}/charges`;
    const queryParams: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {};
    if (params.limit) {
      queryParams.limit = params.limit;
    }
    if (params.sort) {
      queryParams.sort = params.sort;
    }
    if (params.amount) {
      queryParams.amount = params.amount;
    }
    if (params.time) {
      queryParams.time = params.time;
    }
    if (params.skip) {
      queryParams.skip = params.skip;
    }
    return this.http.get<Charge[]>(url, {
      params: queryParams,
    });
  }

  getMachine(machineId: string): Observable<Machine> {
    const url = `${environment.backend.apiBaseUrl}/machines/${machineId}`;
    return this.http.get<Machine>(url);
  }

  listDataCenters(): Observable<DataCenter[]> {
    const url = `${environment.backend.apiBaseUrl}/datacenters`;
    return this.http.get<DataCenter[]>(url);
  }

  createFeedback(params: FeedbackParams): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/feedbacks`;
    return this.http.post<void>(url, params);
  }

  createApiKey(): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/apikeys`;
    return this.http.post<void>(url, {});
  }

  deleteApiKey(apiKeyId: string): Observable<void> {
    const url = `${environment.backend.apiBaseUrl}/apikeys/${apiKeyId}`;
    return this.http.delete<void>(url);
  }

  listApiKeys(): Observable<ApiKey[]> {
    const url = `${environment.backend.apiBaseUrl}/apikeys`;
    return this.http.get<ApiKey[]>(url);
  }

  createCheckoutSession(
    params: CreateCheckoutSessionParams
  ): Observable<CreateCheckoutSessionResult> {
    const url = `${environment.backend.apiBaseUrl}/checkout-sessions`;
    return this.http.post<CreateCheckoutSessionResult>(url, params);
  }
}
