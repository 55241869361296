<div class="flex justify-center items-center">
  <!-- INITIATE -->
  <div *ngIf="step === 'initiate'" class="w-full sm:w-3/4 xl:w-1/2 2xl:w-1/3">
    <h2 class="text-white mb-3">Reset your password</h2>
    <p class="mb-6 text-sm">
      Please enter your email address. We will send you an email to reset your
      password.
    </p>

    <form [formGroup]="resetPasswordForm" novalidate>
      <div class="mb-6">
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-300"
          [class.dark:text-red-500]="
            email.invalid && (email.dirty || email.touched)
          "
          >Email</label
        >
        <input
          type="email"
          formControlName="email"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
          [ngClass]="{
            'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400 dark:text-red-400':
              email.invalid && (email.dirty || email.touched)
          }"
        />
        <div *ngIf="email.invalid && (email.dirty || email.touched)">
          <p
            *ngIf="email.errors?.['required']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Email is required.
          </p>
          <p
            *ngIf="email.errors?.['email']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Please provide a valid email address.
          </p>
        </div>
      </div>

      <div class="w-100 flex flex-col items-center justify-center mt-6">
        <button class="btn-primary" (click)="sendEmail()" [disabled]="busy">
          Send email<app-spinner class="ml-2" *ngIf="busy"></app-spinner>
        </button>
        <p *ngIf="error" class="mt-4 text-sm text-red-600 dark:text-red-500">
          {{ error }}
        </p>
        <p class="mt-12 text-sm">
          Remembered it again? <a routerLink="/login">Login</a>
        </p>
      </div>
    </form>
  </div>

  <!-- CONFIRM -->
  <div *ngIf="step === 'confirm'" class="w-full sm:w-3/4 xl:w-1/2 2xl:w-1/3">
    <h2 class="text-white mb-3">Set a new password</h2>
    <p class="mb-6 text-sm">
      We have sent you an email with a verification code. Please enter the
      verification code below and set a new password.
    </p>

    <form [formGroup]="setPasswordForm" novalidate>
      <div class="mb-6">
        <label
          for="code"
          class="block mb-2 text-sm font-medium text-gray-300"
          [class.dark:text-red-500]="
            code.invalid && (code.dirty || code.touched)
          "
          >Code</label
        >
        <input
          type="text"
          formControlName="code"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
          [ngClass]="{
            'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400 dark:text-red-400':
              code.invalid && (code.dirty || code.touched)
          }"
        />
        <div *ngIf="code.invalid && (code.dirty || code.touched)">
          <p
            *ngIf="code.errors?.['required']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Code is required.
          </p>
        </div>
      </div>
      <div class="mb-6">
        <label
          for="password"
          class="block mb-2 text-sm font-medium text-gray-300"
          [class.dark:text-red-500]="
            password.invalid && (password.dirty || password.touched)
          "
          >Password</label
        >
        <input
          type="password"
          formControlName="password"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
          [ngClass]="{
            'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400 dark:text-red-400':
              password.invalid && (password.dirty || password.touched)
          }"
        />
        <div *ngIf="password.invalid && (password.dirty || password.touched)">
          <p
            *ngIf="password.errors?.['required']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Password is required.
          </p>
          <p
            *ngIf="password.errors?.['minlength']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Please enter at least 8 characters.
          </p>
          <p
            *ngIf="!password.errors?.['minlength'] && password.errors?.['pattern']"
            class="mt-2 text-sm text-red-600 dark:text-red-500"
          >
            Please use at least one letter, one number and one special character
            (@#$%^&+=!*).
          </p>
        </div>
      </div>

      <div class="w-100 flex flex-col items-center justify-center mt-6">
        <button class="btn-primary" (click)="setPassword()" [disabled]="busy">
          Set password<app-spinner class="ml-2" *ngIf="busy"></app-spinner>
        </button>
        <p *ngIf="error" class="mt-4 text-sm text-red-600 dark:text-red-500">
          {{ error }}
        </p>
      </div>
    </form>
  </div>
  <div *ngIf="step === 'redirect'" class="mt-20 text-center">
    <p class="text-2xl">
      We have set your new password. You can now
      <a routerLink="/machines">log in.</a>
    </p>
    <p class="mt-5">We will redirect you shortly.</p>
  </div>
</div>
