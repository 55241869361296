import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable, throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, filter, finalize } from "rxjs/operators";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public authCheck$: Observable<boolean>;
  public loginForm: FormGroup;
  public success = false;
  public error = "";
  public busy = false;
  public from?: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.loginForm = this.fb.group(
      {
        email: [undefined, [Validators.required, Validators.email]],
        password: [undefined, [Validators.required]],
      },
      { updateOn: "blur" }
    );

    this.route.queryParams.pipe(filter((p) => p.r)).subscribe((p) =>
      this.loginForm.patchValue({
        referral_code: p.r,
      })
    );
    this.route.queryParams
      .pipe(filter((p) => p.from))
      .subscribe((p) => (this.from = p.from));
  }

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe((authenticated) => {
      if (authenticated) {
        this.router.navigateByUrl(this.from || "/");
      }
    });
  }

  public login() {
    this.error = "";
    this.busy = true;

    if (!this.loginForm.valid) {
      this.busy = false;
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService
      .login(this.email.value, this.password.value)
      .pipe(
        catchError((err) => {
          // if email has not been provided as lowercase, retry with lowercase email
          // for new accounts all emails should be stored as lowercase but legacy accounts might still have emails including capital letters
          const lowerCaseEmail = (this.email.value as string).toLowerCase();
          if (this.email.value !== lowerCaseEmail) {
            return this.authService.login(lowerCaseEmail, this.password.value);
          }
          return throwError(err);
        }),
        finalize(() => (this.busy = false))
      )
      .subscribe(
        () => {
          this.router.navigateByUrl(this.from || "/");
        },
        () => (this.error = "Wrong username or password")
      );
  }

  public get email() {
    return this.loginForm.get("email") as FormControl;
  }

  public get password() {
    return this.loginForm.get("password") as FormControl;
  }
}
