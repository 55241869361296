<footer class="text-gray-400 bg-gray-900 body-font">
  <div
    class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col"
  >
    <p class="text-sm text-gray-400 sm:py-2 sm:mt-0 mt-4">
      © 2021-{{ year }} {{ copyrightHolderName }}
    </p>
    <span
      class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start text-sm"
    >
      <a class="ml-3 text-gray-400" [href]="termsUrl" target="_blank"
        >Terms & Conditions</a
      >
      <a class="ml-3 text-gray-400" [href]="privacyPolicyUrl" target="_blank"
        >Privacy Policy</a
      >
      <a class="ml-3 text-gray-400" [href]="cookiePolicyUrl" target="_blank"
        >Cookie Policy</a
      >
    </span>
  </div>
</footer>
