import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminGuard } from "./core/auth/admin.guard";
import { AuthGuard } from "./core/auth/auth.guard";
import { SignupComponent } from "./core/auth/signup/signup.component";
import { LoginComponent } from "./core/auth/login/login.component";
import { ResetPasswordComponent } from "./core/auth/reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "machines",
    loadChildren: () =>
      import("./modules/machine/machine.module").then((m) => m.MachineModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
    canActivateChild: [AdminGuard],
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "**",
    redirectTo: "machines",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
