import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { Amplify, Auth } from "aws-amplify";
import { environment } from "src/environments/environment";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    });
  }

  public signUp(user: IUser): Observable<void> {
    return from(
      Auth.signUp({
        username: user.email,
        password: user.password,
        autoSignIn: {
          enabled: true,
        },
      })
    ).pipe(map(() => null));
  }

  public confirmSignUp(user: IUser): Observable<any> {
    return from(Auth.confirmSignUp(user.email, user.code));
  }

  public login(email: string, password: string): Observable<void> {
    return from(Auth.signIn(email, password)).pipe(
      mergeMap(() => from(Auth.currentAuthenticatedUser())),
      map(() => null)
    );
  }

  public logout(): Observable<void> {
    return from(Auth.signOut());
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(
      map((user: any) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public getUser(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }

  public getToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      map((s) => {
        const accessToken = s.getAccessToken();
        return accessToken.getJwtToken();
      })
    );
  }

  verifyEmail(email: string, code: string): Observable<boolean> {
    return from(Auth.confirmSignUp(email, code)).pipe(map((r) => r!!));
  }

  forgotPassword(email: string): Observable<void> {
    return from(Auth.forgotPassword(email));
  }

  confirmForgotPassword(
    email: string,
    code: string,
    password: string
  ): Observable<void> {
    return from(Auth.forgotPasswordSubmit(email, code, password)).pipe(
      map((a) => null)
    );
  }
}
