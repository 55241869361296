<header class="text-gray-400 bg-gray-900 body-font">
  <div
    class="container mx-auto flex flex-wrap p-5 gap-x-5 gap-y-2 items-center"
  >
    <a
      routerLink="/"
      class="flex title-font font-medium items-center text-white sm:mb-0 hover:text-white"
    >
      <img src="assets/logo.png" style="height: 36px" />
      <span class="ml-3 text-xl mr-3">{{ appName }}</span>
      <span class="bg-indigo-600 text-white px-2 rounded-lg">Beta</span>
    </a>
    <nav
      class="ml-auto flex flex-wrap items-center text-base justify-center gap-x-2"
    >
      <a
        href="https://help.airgpu.com"
        target="_blank"
        class="hover:text-white text-sm"
        >
        <div class="flex items-center">
          <i-feather name="life-buoy" class="mr-1"></i-feather>
          <span>Help</span>
        </div>
        </a
      >
    </nav>

    <div
      class="flex justify-center"
      *ngIf="auth.userProfile$ | async as profile"
      (clickOutside)="onClickOutside()"
    >
      <div class="relative">
        <button
          class="block h-12 w-12 rounded-full overflow-hidden focus:outline-none"
          (click)="collapsed = !collapsed"
        >
          <!-- <img class="h-full w-full object-cover" [src]="profile.picture" alt="avatar"> -->
          <div
            class="rounded-full h-12 w-12 flex items-center justify-center bg-gray-800 hover:bg-gray-700"
          >
            <i-feather name="user" class="text-white"></i-feather>
          </div>
        </button>
        <div
          *ngIf="!collapsed"
          class="absolute right-0 w-48 p-2 mt-2 bg-gray-800 rounded-lg"
          (click)="collapsed = true"
        >
          <a
            routerLink="admin/statistics"
            *ngIf="user && user['https://api.airgpu.com/admin']"
            class="transition-colors duration-200 block px-4 py-2 text-normal text-gray-200 rounded-lg hover:bg-gray-700 hover:text-white"
            >Statistics</a
          >
          <a
            routerLink="account"
            class="transition-colors duration-200 block px-4 py-2 text-normal text-gray-200 rounded-lg hover:bg-gray-700 hover:text-white"
          >
            Account
          </a>
          <a
            (click)="logout()"
            class="transition-colors duration-200 block px-4 py-2 text-normal text-gray-200 rounded-lg hover:bg-gray-700 hover:text-white cursor-pointer"
          >
            Logout
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
