<ng-container *ngIf="!(authCheck$ | async); else loading">
  <div class="flex justify-center items-center">
    <div class="w-full sm:w-3/4 xl:w-1/2 2xl:w-1/3">
      <h2 class="text-white">Login</h2>

      <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
        <div class="mb-6">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-300"
            [class.dark:text-red-500]="
              email.invalid && (email.dirty || email.touched)
            "
            >Email</label
          >
          <input
            type="email"
            formControlName="email"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
            [ngClass]="{
              'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400 dark:text-red-400':
                email.invalid && (email.dirty || email.touched)
            }"
          />
          <div *ngIf="email.invalid && (email.dirty || email.touched)">
            <p
              *ngIf="email.errors?.['required']"
              class="mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Email is required.
            </p>
            <p
              *ngIf="email.errors?.['email']"
              class="mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Please provide a valid email address.
            </p>
          </div>
        </div>
        <div class="mb-6">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-300"
            [class.dark:text-red-500]="
              password.invalid && (password.dirty || password.touched)
            "
            >Password</label
          >
          <input
            type="password"
            formControlName="password"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500 dark:shadow-sm-light"
            [ngClass]="{
              'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400 dark:text-red-400':
                password.invalid && (password.dirty || password.touched)
            }"
            (keydown.enter)="$event.target.blur(); login()"
          />
          <div *ngIf="password.invalid && (password.dirty || password.touched)">
            <p
              *ngIf="password.errors?.['required']"
              class="mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Password is required.
            </p>
          </div>
        </div>

        <div class="w-100 flex flex-col items-center justify-center mt-6">
          <button type="submit" class="btn-primary" [disabled]="busy">
            Login<app-spinner class="ml-2" *ngIf="busy"></app-spinner>
          </button>
          <p *ngIf="error" class="mt-4 text-sm text-red-600 dark:text-red-500">
            {{ error }}
          </p>
          <p class="mt-12 text-sm">
            Not a member yet? <a routerLink="/signup">Sign up</a>
          </p>
          <p class="mt-4 text-sm">
            Forgot your password? <a routerLink="/reset-password">Reset it</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="w-full flex justify-center">
    <app-spinner></app-spinner>Loading
  </div>
</ng-template>
