import { NgModule } from "@angular/core";

import { FeatherModule } from "angular-feather";
import {
  Monitor,
  AlertCircle,
  User,
  Star,
  ArrowDown,
  ArrowUp,
  MoreHorizontal,
  RefreshCw,
  ArrowRight,
  ExternalLink,
  ArrowLeft,
  HardDrive,
  CheckCircle,
  LifeBuoy,
} from "angular-feather/icons";

// Select some icons (use an object, not an array)
const icons = {
  Monitor,
  AlertCircle,
  User,
  Star,
  ArrowDown,
  ArrowUp,
  MoreHorizontal,
  RefreshCw,
  ArrowRight,
  ArrowLeft,
  ExternalLink,
  HardDrive,
  CheckCircle,
  LifeBuoy,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconModule {}
