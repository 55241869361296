export const environment = {
  production: true,

  auth0: {
    domain: "airgpu.eu.auth0.com",
    clientId: "BUq6S9BnXWpDOrZxSHAOxqjpX0usFYS3",
    audience: "https://api.airgpu.com",
  },
  general: {
    appName: "airgpu",
    termsUrl: "https://airgpu.com/terms",
    privacyPolicyUrl: "https://airgpu.com/privacy",
    cookiePolicyUrl: "https://airgpu.com/cookies",
    copyrightHolderName: "airgpu",
  },
  backend: {
    apiBaseUrl: "https://api.airgpu.com",
    stackprintBaseUrl: "https://apis.stackprint.io/airgpu",
  },
  paddle: {
    vendorId: 126869,
    products: {
      10: 745053,
      25: 745057,
      50: 745058,
    },
    sandboxMode: false,
  },
  stripe: {
    creditPriceIds: {
      10: "price_1PTJ5WCQhqTYQJvyN5e3eIrj",
      25: "price_1PTJ6cCQhqTYQJvyT768OUnA",
      50: "price_1PTJ7OCQhqTYQJvyejuNLhFy",
    },
    promotionCodeIds: {
      CGBATTLE: "promo_1PZAHQCQhqTYQJvyu1009JXD",
    },
  },
  hcaptcha: {
    siteKey: "c74568db-f282-4c89-b66e-6dc6db01b1d3",
  },
  features: {
    buyCredits: true,
    multipleMachines: true,
    signup: true,
  },
  cognito: {
    userPoolId: "eu-central-1_lmhPyMfpK",
    userPoolWebClientId: "2gdu68fihh410sp7553b6ovm5n",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};
